import $ from 'jquery';
import Splide from '@splidejs/splide';
import Inputmask from 'inputmask';
import '@splidejs/splide/css';

function handleInputPhone() {
    new Inputmask('+7 (999) 999-99-99').mask($('.input-phone'));
}

function handleFocusInput() {
    $('input').focus((event) => {
        $(event.target).removeClass('error');
    })
}

function openPopup($popup, data) {
    $popup.addClass('shown');
    $('body').css({'overflow-y': 'hidden'});

    if ($popup.attr('id') === 'popupForm') {
        let $inputCourse = $popup.find('#id_course');
        if (data) {
            $inputCourse.val(data.courseId);
        }
        else {
            $inputCourse.val('');
        }
    }
}

function closePopup($popup) {
    $popup.removeClass('shown');
    $('body').css({'overflow-y': 'auto'});
}

function initLets() {
    let $popupForm = $('#popupForm');
    let $popupMenu = $('#popupMenu');
    let $popupsEnroll = $('.popup-enroll');
    let $popupThankYou = $('#popupThankYou');
    let $popupsEnrollMulti = $('.popup-enroll-multi');

    handleInputPhone();
    handleFocusInput();

    $('a[href^="#"]').click(function () {
        let href = $(this).attr('href');
        $('html,body').animate({scrollTop: $(href).offset().top},'slow');
    });

    $('[data-popup-id*=popup]').click((event) => {
        let popup = $(event.currentTarget).attr('data-popup-id');
        let $popup = $(`#${popup}`);
        openPopup($popup);
    })
    $('.popup').click((event) => {
        if (event.target === event.currentTarget) {
            closePopup($(event.target));
        }
    })
    $('.btn-popup-close').click(event => closePopup($(event.currentTarget).parent().parent()));

    $popupMenu.find('li').children().click(() => {
        closePopup($popupMenu);
    });
    $popupsEnroll.find('.btn').click((event) => {
        let courseId = parseInt($(event.target).attr('data-course-id'));
        closePopup($popupsEnroll);
        openPopup($popupForm, {courseId: courseId});
    })
    $popupsEnrollMulti.find('.card').click(() => {
        let courseId = parseInt($(event.currentTarget).attr('data-course-id'));
        closePopup($popupsEnrollMulti);
        openPopup($popupForm, {courseId: courseId});
    });

    new Splide( '.splide' , {
        type: 'loop', perPage: 3, gap: 15,
    }).mount();

    $('.form').submit((event) => {
        let $form = $(event.target);
        let $button = $form.find('button[type=submit]');
        $button.prop('disabled', true);

        let data = $form.serialize();
        $.ajax({
            method: 'POST', data: data
        }).done((response) => {
            if (response.success === -1) {
                alert('Ошибка сервера!');
            }
            else if (response.success === 0) {
                $form.trigger('reset');
                closePopup($popupForm);
                openPopup($popupThankYou);
            }
            else {
                $form.children('.widgets').html(response.html);
                handleInputPhone();
                handleFocusInput();
            }
        }).fail(() => {
            alert('Ошибка сервера!');
        }).always(() => {
            $button.prop('disabled', false);
        });
        event.preventDefault();
    });
}

function initSchool() {
    $('.t396__artboard[data-artboard-recid=679092834]').parent().hide();
    $('.tn-elem__6791838781702196761628').hide(); // bg
    $('.tn-elem__6791838781702196889484').hide(); // text
    $('.tn-elem__6791838781702196680954').hide(); // input
    $('.tn-elem__6791838781702196745642').hide(); // button

    $('.t-submit').click((event) => {
        let $button = $(event.target);
        const $form = $button.parent().parent().parent();
        const data = $form.serialize();

        $button.attr('disabled', true);
        $button.css('cursor', 'wait');
        $button.css('opacity', 0.5);
        event.preventDefault();
        event.stopPropagation();
        $.ajax({
            method: 'POST',
            data: data
        }).done((response) => {
            if (response.success === 0) {
                $('.t-popup__block-close-button').click(); // close popup
                alert('Заявка сохранена! Скоро мы свяжемся с вами для уточнения деталей');
                $form.find('input').val('');
            } else {
                alert('Ошибка сервера!');
            }
        }).fail(() => {
            alert('Ошибка сервера!');
        }).always(() => {
            $button.attr('disabled', false);
            $button.css('cursor', 'default');
            $button.css('opacity', 1);
            $('html').css('cursor', 'default');
        })
    });
}

document.addEventListener("DOMContentLoaded", () => {
    const $mainLets = $('#main-lets');
    if ($mainLets && $mainLets.length > 0) {
        initLets()
    } else {
        initSchool();
    }
});
